import { SvgContainerViewProps } from '../../../../components/SvgContainer/types';

export type ComponentViewProps = Omit<SvgContainerViewProps, 'viewBox'>;

export enum CountriesIcons {
  'KOH' = 'KOH',
  'GB' = 'GB',
  'ET' = 'ET',
  'CN' = 'CN',
  'PY' = 'PY',
  'US' = 'US',
  'RU' = 'RU',
  'SGP-1' = 'SGP-1',
  'CD' = 'CD',
  'EE' = 'EE',
  'GE' = 'GE',
  'AU' = 'AU',
  'GQ' = 'GQ',
  'WS' = 'WS',
  'GH' = 'GH',
  'BR' = 'BR',
  'MT' = 'MT',
  'JE' = 'JE',
  'MG' = 'MG',
  'MW' = 'MW',
  'MN' = 'MN',
  'NP' = 'NP',
  'HT' = 'HT',
  'FK' = 'FK',
  'IM' = 'IM',
  'CI' = 'CI',
  'LS' = 'LS',
  'RW' = 'RW',
  'MX' = 'MX',
  'ZW' = 'ZW',
  'CG' = 'CG',
  'LK' = 'LK',
  'TJ' = 'TJ',
  'TT' = 'TT',
  'XK' = 'XK',
  'PF' = 'PF',
  'MV' = 'MV',
  'MD' = 'MD',
  'ME' = 'ME',
  'PG' = 'PG',
  'TL' = 'TL',
  'LY' = 'LY',
  'MK' = 'MK',
  'SD' = 'SD',
  'UZ' = 'UZ',
  'YE' = 'YE',
  'IN' = 'IN',
  'LI' = 'LI',
  'SM' = 'SM',
  'BL' = 'BL',
  'CW' = 'CW',
  'DJ' = 'DJ',
  'ER' = 'ER',
  'GW' = 'GW',
  'SY' = 'SY',
  'KG' = 'KG',
  'ML' = 'ML',
  'AZ' = 'AZ',
  'CA' = 'CA',
  'MH' = 'MH',
  'FM' = 'FM',
  'NE' = 'NE',
  'ST' = 'ST',
  'SOM-1' = 'SOM-1',
  'TC' = 'TC',
  'NL' = 'NL',
  'BS' = 'BS',
  'TG' = 'TG',
  'TM' = 'TM',
  'TV' = 'TV',
  'VA' = 'VA',
  'AT' = 'AT',
  'GA' = 'GA',
  'TH' = 'TH',
  'GL' = 'GL',
  'GD' = 'GD',
  'GU' = 'GU',
  'GG' = 'GG',
  'KW' = 'KW',
  'MR' = 'MR',
  'MS' = 'MS',
  'PT' = 'PT',
  'NR' = 'NR',
  'MP' = 'MP',
  'PS' = 'PS',
  'PN' = 'PN',
  'KN' = 'KN',
  'SX' = 'SX',
  'VN' = 'VN',
  'SB' = 'SB',
  'SO' = 'SO',
  'SS' = 'SS',
  'VC' = 'VC',
  'GM' = 'GM',
  'TK' = 'TK',
  'GN' = 'GN',
  'ENG' = 'ENG',
  'SC' = 'SC',
  'SL' = 'SL',
  'VU' = 'VU',
  'DM' = 'DM',
  'FO' = 'FO',
  'FJ' = 'FJ',
  'MAC' = 'MAC',
  'NU' = 'NU',
  'PW' = 'PW',
  'SG' = 'SG',
  'LC' = 'LC',
  'TO' = 'TO',
  'BG' = 'BG',
  'HR' = 'HR',
  'BO' = 'BO',
  'KH' = 'KH',
  'CR' = 'CR',
  'BD' = 'BD',
  'BW' = 'BW',
  'DZ' = 'DZ',
  'AO' = 'AO',
  'AF' = 'AF',
  'AM' = 'AM',
  'PH' = 'PH',
  'TR' = 'TR',
  'BF' = 'BF',
  'AL' = 'AL',
  'BH' = 'BH',
  'BZ' = 'BZ',
  'BMU' = 'BMU',
  'BA' = 'BA',
  'VGB' = 'VGB',
  'BB' = 'BB',
  'BY' = 'BY',
  'PL' = 'PL',
  'BN' = 'BN',
  'CM' = 'CM',
  'AG' = 'AG',
  'BI' = 'BI',
  'Welsh' = 'Welsh',
  'AD' = 'AD',
  'BJ' = 'BJ',
  'CH' = 'CH',
  'CV' = 'CV',
  'TD' = 'TD',
  'IOT' = 'IOT',
  'CYM' = 'CYM',
  'CF' = 'CF',
  'KM' = 'KM',
  'AIA' = 'AIA',
  'MA' = 'MA',
  'ASM' = 'ASM',
  'CXR' = 'CXR',
  'CCK' = 'CCK',
  'CK' = 'CK',
  'TWN' = 'TWN',
  'EG' = 'EG',
  'NO' = 'NO',
  'ID' = 'ID',
  'FR' = 'FR',
  'ZA' = 'ZA',
  'PE' = 'PE',
  'SW' = 'SW',
  'DK' = 'DK',
  'IE' = 'IE',
  'LA' = 'LA',
  'AE' = 'AE',
  'HKG' = 'HKG',
  'DE' = 'DE',
  'UA' = 'UA',
  'AR' = 'AR',
  'IR' = 'IR',
  'CO' = 'CO',
  'CZ' = 'CZ',
  'IL' = 'IL',
  'FI' = 'FI',
  'SA' = 'SA',
  'VE' = 'VE',
  'MY' = 'MY',
  'KR' = 'KR',
  'KY' = 'KY',
  'BE' = 'BE',
  'HU' = 'HU',
  'CL' = 'CL',
  'RO' = 'RO',
  'EC' = 'EC',
  'NZ' = 'NZ',
  'PA' = 'PA',
  'SK' = 'SK',
  'DO' = 'DO',
  'ES' = 'ES',
  'MC' = 'MC',
  'JO' = 'JO',
  'IS' = 'IS',
  'GT' = 'GT',
  'UPY' = 'UPY',
  'KP' = 'KP',
  'RS' = 'RS',
  'KZ' = 'KZ',
  'JP' = 'JP',
  'LV' = 'LV',
  'PK' = 'PK',
  'CY' = 'CY',
  'KE' = 'KE',
  'MM' = 'MM',
  'HN' = 'HN',
  'JM' = 'JM',
  'LT' = 'LT',
  'LU' = 'LU',
  'EU' = 'EU',
  'NA' = 'NA',
  'ZM' = 'ZM',
  'SV' = 'SV',
  'NI' = 'NI',
  'AW' = 'AW',
  'TN' = 'TN',
  'IT' = 'IT',
  'NG' = 'NG',
  'UG' = 'UG',
  'LB' = 'LB',
  'IQ' = 'IQ',
  'MZ' = 'MZ',
  'PR' = 'PR',
  'SI' = 'SI',
  'OM' = 'OM',
  'SN' = 'SN',
  'LR' = 'LR',
  'CU' = 'CU',
  'GR' = 'GR',
  'TZ' = 'TZ',
  'AQ' = 'AQ',
  'BT' = 'BT',
  'BV' = 'BV',
  'SZ' = 'SZ',
  'GF' = 'GF',
  'TF' = 'TF',
  'GI' = 'GI',
  'GP' = 'GP',
  'GY' = 'GY',
  'KI' = 'KI',
  'MQ' = 'MQ',
  'MU' = 'MU',
  'YT' = 'YT',
  'NC' = 'NC',
  'NF' = 'NF',
  'QA' = 'QA',
  'RE' = 'RE',
  'SH' = 'SH',
  'MF' = 'MF',
  'PM' = 'PM',
  'GS' = 'GS',
  'SR' = 'SR',
  'SJ' = 'SJ',
  'UM' = 'UM',
  'UY' = 'UY',
  'VI' = 'VI',
  'WF' = 'WF',
  'EH' = 'EH',
  'AX' = 'AX',
  'HK' = 'HK'
}

export interface CountriesIconsViewProps
  extends Omit<SvgContainerViewProps, 'viewBox' | 'preserveAspectRatio'> {
  glyph: keyof typeof CountriesIcons;
}
